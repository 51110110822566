
.container_main {
  padding-left: 40px;
  padding-right: 40px;
}

.container_direccion {
  display: flex;
  flex-wrap: wrap;
  // margin-right: -0.75rem;
  // margin-left: -0.75rem;
  padding: 1rem;
}

.style_indice_number_imagen{
  width: 47px !important;
  bottom: 0;
  text-align: center;
  float: right;
  margin-right: 15px !important;
  font-size: 16px;
/*  position: absolute;


    right: 0;
  */

}

.body-image{
  position: relative;
  max-width: 445px !important;
  margin-right: 80px !important;
  
  min-height: 25rem;
}
.direccionvertical{

}
.style_precio_kg{
  font-size: 16px;
}
.container_direccion > * {
  padding: 0.75rem;
  flex: 1;
  min-width: 0;
  padding: 3px 20px;
}

.container_info_prod {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  padding: 4px 4px;
}

.style_containerDir{
  padding-right: 20px;
}
.container_det_prod_last_row{
  display: flex;
  min-width: 30rem;
  justify-content: space-between;
}

.contenedorInfo{
  width: 100%;
  height: 100%;
  display: flex;
}
.bordes{
  border:1px solid red;

}
 .block-total{
  border-bottom: 1px solid  #ABABAD;
   padding-top: 8px;
   padding-bottom: 8px;
}


.controles-add-minus, .block-total{
  width: 100%;
  height: auto;

}
.columnaImagen, .columnaInfoProd{
   min-height: 500px;
}
.columnaImagen{
  width: 60%;
  border-right: 3px solid #ABABAD;
  display: flex;
}
.columnaInfoProd{
  width: 40%;
  z-index: 10 !important;
  padding-left: 1rem;
}

.imagen-grande, .imagenes-vertical{
  min-height: 500px;
}
.imagenes-vertical{
   min-width:20%;
  display: flex;
  align-items: center;
}
.imagen-grande {
  min-width: 80%;
 position: relative;

}

.columnaImagen{
  /* border: 10px solid pink;*/
}

.style-name-producto{

  font-size: 24px;
  font-weight: bold;
  height: auto;
  padding: 5px 5px 5px 0px;

}
.precio-tag{
  width: 100%;
  height: auto;
}
.bnt-agregar-agregar{
  width: 100%;
  padding: 5px 5px;
}
.header-corazon{
  width: 100%;
  height: 60px;

}
.style-categorias{
  margin-bottom: 3px;
  height: auto;
}
 .descuento-tag,     .tag-agregar-listas{
  width: 100%;
  height: 60px;
}
.texto-precio{

  font-weight: bold;
  height: auto;
  padding: 5px 5px 5px 0px;
}
.tag-agregar-listas{
  text-decoration: underline;
}

.valor-ahora-info-prod{
  font-size: 32px;
}

.style-letra-info-prod{
  font-family: Inter, sans-serif;
  font-weight: bold;

}
.style-texto-total{
  font-size: 24px;
}
.valor-antes-info-prod{
  font-size: 24px;
  color: #9CA3AF;
  text-decoration: line-through
}
.header-responsive-categorias{
  display: none;
  width: 100%;
  height: auto;
}

.icono-delete{
  padding: 4px 8px 4px 7px;
  border-radius: 5px;
}

.imagen-carrusel{
  display: none;
}
.imagenes-responsive-footer{
  display: none;
}
.container-favorito-resposive{
  display: none;
}

.style_precio_und{
  font-size: 16px;
}

.entre_peso_aprox{
  width: 198px;
}

.alineado_seccion{
  width: auto !important;
  margin-right: 16px !important;
}

@media screen and(max-width: 750px) {

  .style-categorias{
    display: none;
  }
  .header-responsive-categorias{
    display: block;
  }

  .columnaImagen{
    border: none;
    height: auto !important;
  }

  .contenedorInfo{
    display: flex;
    flex-direction: column;
  }
  .columnaImagen, .columnaInfoProd{
    width: 100%;
  }
  .columnaImagen {
    height: 350px;
    min-height: 350px !important;
  }

  .imagenes-vertical{
    min-height: 100px;
      height: 300px;

  }

  .columnaImagen{
   display: flex;
    flex-direction: column ;

  }
  .imagen-grande{
    min-height: 95px;
    height: 95px !important;
  }
  .imagen_body, .style_indice_number_imagen{
    display: none;
  }

  .imagenes-vertical{
    display: none !important;

  }
  .imagen-carrusel{
    display: block;

    height:auto !important;
    width: 100%;
    max-height: 235px !important;

  }
  .imagenes-responsive-footer{
    display: flex;

    height: 100px;
    width: 100%;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100 !important;
  }

  .header-corazon{
    display: none;
  }

  .container-favorito-resposive{
    display: block;
    width: 100%;
    min-height: 50px;

    height: auto;

  }
  .header-corazon-responsive{
    display: block;
  }

  .style-name-producto{
    font-size: 20px;
  }

  .style_precio_und{
    font-size: 14px;
  }
  .valor-ahora-info-prod{
    font-size: 24px;
  }

  .columnaInfoProd{
    padding-left: 5px;
    padding-right: 5px;
  }

  .style_dato_peso_unidad{
    width: 50%;
  }

  .body-image{
  margin-right: 0px !important;
  }

}
@media screen and(max-width: 420px) {
  .container_seccion_main {
    display: flex;
    flex-direction: column !important;
  }
  .container_seccion {
    position: relative !important;
    display: flex;
    justify-content: center;
  }

  .container_productos {
    margin-top: 30px;
  }
  .footer-button {
    display: block;
    position: fixed; /* Fijar el pie de página en la ventana del navegador */
    bottom: 0;

    right: 0;

  }
  .container_main {
    padding-left: 0px;
    padding-right: 0px;
  }

}


.texto_ahorrado_info_prod{
  border-radius: 20px;
   padding-left: 13px;
  padding-right: 13px;
margin-left: 20px;

}
@media screen and(max-width: 1285px) {
  .container_direccion {
    display: flex;
    flex-direction: column;
  }

}

@media screen and(max-width: 486px) {
  // .container_det_prod_last_row {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;
  // }


  .texto_ahorrado_info_prod .controles_info_prod {
    width: 95%;
  }

}

@media screen and(max-width: 451px) {
  // .container_det_prod_last_row {
  //   align-items: flex-start;

  //   div {
  //     width: 98% !important;
  //     flex-direction: column;
  //     justify-content: space-between;
  //   }
  // }
  .controles_info_prod {
    div {
      flex-direction: row;
      padding-left: 1px;
      padding-right: 1px;
    }

  }
}

@media screen and(max-width: 770px) {
  .container_info_prod {
    display: flex;
    flex-direction: column;
  }

  .container_info_detalle_prod {
    width: 100% !important;
  }

}

@media screen and(max-width: 768px) {
  .compra_rapida_form_pago {
    width: 100% !important;
    flex-direction: column;

    div {
      width: 100% !important;
    }
  }

  .botones_compra_rap_form_pago {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
  .container_det_prod_last_row{
    display: block;
    min-width: 30rem;
  }


}
.main_container_update_direccionA {
  width: 100% !important;
}


@media screen and(max-width: 1286px) {
  .container_direccion {
    flex-direction: column-reverse;
    align-content: center;
    // padding-left: 30px;
    // padding-right: 30px;
    padding: 1rem;

    div {
      width: 100% !important;
    }
  }
  .main_container_update_direccionA {
    width: auto !important;
  }


}
@media screen and(max-width: 1276px) {
  .style_containerDir{
    padding-right: 0px;
  }

}
@media screen and(max-width: 575px) {
  .container_direccion {
    height: 100% !important;
    padding: 5px;
    padding-bottom: 2rem;
  }

  .container_direccion > * {

    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;

  }
}


/*swiper*/


/*swiper*/
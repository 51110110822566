/* $primario:#DC842D;
$secundario: #32338E; */

:root {

    --primario: #ED6A0F;
    --secundario: #0349AB;
    --tercero:#B3CE6B;
    --cuatro:#84B111;
    --color_verde:#53DD12;
    --texto:#7C7C7C;
    --texto_nuevo:#0349AB;
    
    --negro:#000000;
    --plomo_fuerte:#5A5A5A;
    --plomo_suave:#9CA3AF;

    --hover_primario:#c04e0e;
    --hover_secundario:#0078ff;
    --hover_cuatro:#5b7a11;

    /* --primario: #DC842D;
    --secundario: #32338E;
    --tercero:#B3CE6B;
    --cuatro:#84B111;
    --color_verde:#53DD12;
    --texto:#7C7C7C;
    --texto_nuevo:#0349AB;

    --hover_primario:#c1601e;
    --hover_secundario:#4645e6;
    --hover_cuatro:#5b7a11; */

    /* #0349AB Azul
    #ED6A0F Naranja
    #FFC8A3 Naranja Claro
    #000000 Negro
    #5A5A5A plomo fuerte
    #9CA3AF plomo suave */

    
  }

.bg_primario{
    background-color: var(--primario);
}
.text_primario{
    color:var(--primario);
}
.bg_secundario{
    background-color: var(--secundario);
}

.text_secundario{
    color:var(--secundario);
}
.text_tercero{
    color:var(--tercero);
}
.border_primario{
    border: 1px solid var(--primario);
}
.border_secundario{
    border: 1px solid var(--secundario);
}
.border_white{
    border: 1px solid #FFFFFF;
}
.text_gray{
    color:var(--texto);
}
.border_black{
    border: 1px solid var(--negro);
}

.fontzize_label{
    font-size: 15px;
    font-weight: 600;
}

.radius_input{
    border-radius: 8px;
}

.nsm7Bb-HzV7m-LgbsSe{
    background-color: transparent !important;
}

.contenedor_google{
 position: relative;
}

.boton_google{
    position: absolute;
    opacity: 0  !important;
    left: 18px;
    top: 1px;
    height: 40px;
    width: 100% !important;

}
.boton_google2{
    width: 100% !important;
}
.boton_google2,.boton_google3{
   /*border: 2px solid blue;*/
}
/*

.nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb{
    display: none !important;
}
.n1UuX-DkfjY{
    display: none !important;
}

.container{
    background-color: red !important;
}
*/

.alineado_categoria{
    width: auto !important;
    margin-right: 0px !important;
}

.bg_image_page{
    background-image: url('../images/fondo_page.png');
    height: 100vh;
    width: auto;
    background-size: cover;
    background-position: center;
    // display: flex; 
    // flex-direction: column;
    // justify-content: center;
    // align-items:center;
    // min-height: 70rem;
    // height: 70rem;
    background-attachment: fixed; 

}
#theIdCart {
  width: 72%;
  overflow: hidden;
}
.text_precio{
  font-size: 17px;
  font-weight: bold;
}
@media (max-width: 1399.98px) {
  #theIdCart {
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  #theIdCart {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  #theIdCart {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  #theIdCart {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  #theIdCart {
    width: 100%;
  }
}

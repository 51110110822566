

.imagenes-vertical, .imagen-carrusel{
  .swiper-button-next,
  .swiper-button-prev, #mySwiperInfoProductov  {
      font-size: 10px;
  }

  .swiper {
    width: 100%;
    height: 100%;
     padding: 30px 20px 20px 20px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;


    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .swiper-vertical .swiper-button-next,
  .swiper-vertical .swiper-button-prev {
    left: 50%;
    transform: rotate(90deg);
    transform-origin: left center;
    color: black;
  }
  .swiper-vertical .swiper-button-prev {
    top: calc(var(--swiper-navigation-size) / 2);
  }
  .swiper-vertical .swiper-button-next {
    top: auto;
    bottom: calc(var(--swiper-navigation-size) / 2);
  }

  .swiper-slide swiper-slide-next{
    height: auto !important;
    min-height: auto !important;
  }

  .swiper-button-next, .swiper-button-prev{
    color:black;
  }
  .swiper-button-next{
    padding-bottom: 40px;
  }

  .swiper-container {
    width: 100%;
    height: 500px; /* Ajusta la altura según tus necesidades */
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .swiper-button-next,
  .swiper-button-prev {

    z-index: 1;
  }
  .swiper-button-next{
    margin-left:-30px !important;
    margin-top: -150px !important;
  }
  .swiper-button-prev {
    margin-left:-10px !important;
    margin-bottom: -50px !important;

  }
  @media screen and(max-width: 750px) {
    .swiper-button-next,
    .swiper-button-prev {

      margin: 0px !important;
    }
    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
      margin-top: 24px !important;
    }

  }
  /*

  */
}

.style_cantidad{
  position: absolute;
  bottom: 1.25rem;
}


.container_detalle_pedido{
display: flex;
  flex-direction: column;
  min-width: 396px ;
  width: 100% !important;
}

.detalle-item{
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-left: 10px !important;
}

.product_description{
  div{
    width: 100% !important;
  }
}

.texto_name_producto{


}

.descripcion{

  p{
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

@media screen and (max-width: 1000px) {
  .detalle-item{

    display: flex;


  }


}



@media screen and (max-width: 510px) {
.descripcion{
  width: 160px !important;
}

  .style_cantidad{
    position: relative !important;
    bottom: 0px;
  }

  .style_precio_subtotal_dp{
     /* padding-top: 45px !important;*/
  }
}
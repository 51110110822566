.footer-button {
  display: none;
}

.container_main {
  padding-left: 40px;
  padding-right: 40px;
}
.content_responsivo_movil{
  display: none;
}
.content_responsivo_desktop{
  display: block;
}
.content_infinit{
  gap:1rem;
}
.slider_opciones{
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;  
  // overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}

.alineado_subcategoria{
  width: auto !important;
  margin-right: 16px !important;
}


@media screen and (max-width: 900px) {
 
  .content_responsivo_desktop{
    display: none;
  }
  .content_responsivo_movil{
    display: block;
  }
  .content_infinit{
    gap:8px;
  }
  .container_main {
    padding-left: 2px;
    padding-right: 2px;
  }

}

@media (max-width: 420px) {
  // .container_categoria {
  //   display: flex;
  //   flex-direction: column !important;
  // }

  // .subcontainer_cat {
  //   position: relative !important;
  //   display: flex;
  //   justify-content: center;
  // }
  .content_infinit{
    gap:8px;
  }

  .container_prod {
    margin-top: 30px;
  }

  .footer-button {
    display: block;
    position: fixed; /* Fijar el pie de página en la ventana del navegador */
    bottom: 0;

    right: 0;

  }
  .container_main {
    padding-left: 0px;
    padding-right: 0px;
  }
}


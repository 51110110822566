
.estilo_radio_estado_pedido_panel_principal{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
}
.estilo_radio_estado_pedido_panel_principal_cabecera{
    border-top-left-radius: 20px;
 
}
.cajaDetPed{
    width: auto;
    height: auto;
    background-color: white !important;
    z-index: 10;
    margin-bottom: 10px;
   
  
    border-top: 1px solid #dc842d;
    border-left: 1px solid #dc842d;
    border-bottom: 1px solid #dc842d;
}
 
.cabecera_estado_pedido_panel_principal{
    /*background-color: #dc842d;*/
    width: 100%;
    height: auto;
    color:white !important;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.titulo_cabecera_state_pedido{
    
     width: 100%;
     display: flex;
     justify-content: space-around;
}
.body_panel_state_pedido{
    width: 100%;
   /* min-height: 100px;*/
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.estilo_container_icono_state_pedido{
    display: flex;
     flex-direction: column;
    align-items: center;
    height: 100px;
    padding-top: 15px;
}
.estilo_container_icono_state_pedido2{
    display: flex;
     flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-top: 0px;
}


.circulo_icono_state_pedidos{
  
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding-top: 8px;
}

.color_morado{
    background-color: #32338e;
}
.color_azul{
    background-color: #32338e;
}
.color_celeste{
    background-color: #00AEFF;
}
.color_verde{
    background-color: #84B111;
}
.color_lila{
    background-color: #B528C2;
}
.color_naranja{
    background-color: #dc842d;
}

.color_plomo{
    background-color: #acacac;
}

.estilo_size_font_icon{
    font-size: 13px !important;
     
}


.estilo_icon_rigth{
    width: 20px;
    height: 80px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
}
.estilo_icon_rigth2{
    width: 20px;
    height: 80px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.style_header_text{
    padding-left: 10px;
    padding-right: 10px;
}
 
.espacio_izq{
    padding-left: 0px;
}

.style_icon_rigth_header
{
    cursor: pointer;
}


.container_oculto{
   /* position: relative;
    right: -365px !important;
    */
    position: absolute;
    right: 2px;
    width: 40px;
    height: auto;
    background-color: white !important;
    z-index: 10;
    margin-bottom: 10px;
   
  
    border-top: 1px solid #dc842d;
    border-left: 1px solid #dc842d;
    border-bottom: 1px solid #dc842d;
}

.container_panel_oculto{
    position: relative !important;
    height: 144px;
}


@media screen and (max-width: 470px) {
    .espacio_izq{
        padding-left: 50px;
    }

}
@media screen and (max-width: 267px) {
    .espacio_izq{
        padding-left: 10px;
    }
}


@media screen and (max-width: 465px) {
    .container_oculto {
       display: none;
    }
}
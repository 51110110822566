nav{display: block;}
#mainnav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#mainnav > ul > li {
    display: inline-block;
    padding: 0 6px;
}
#mainnav ul li {
    position: relative;
}
.header #mainnav > ul > li   {
    line-height: 100px;
}
#mainnav > ul > li.home  {
    color: #fff;
}
#mainnav > ul > li   {
    position: relative;
    display: block;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    margin: 0 10px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
#mainnav div.submenu {
    top: 35px;
    width: 300px;
    text-align: left;
    background-color: #fff;
    z-index: 9999;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
#mainnav div.submenu > div  > ul > li:first-child {
    /*border-top: none;*/
}
 
#mainnav ul li div div ul li {
    margin-left: 0;
}
#mainnav div.submenu   {
    display: block;
    color: blue;
    text-decoration: none;
    padding: 0 0 0 0px;
    line-height: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
#mainnav ul li:hover > div.submenu   {
   
    filter: alpha(opacity=100);
    opacity: 1;
    visibility: visible;
}
body{
  font-family: 'Inter', sans-serif;
}
.wrapper {
  height: 100vh;
  position: relative;
}

#Header {
  .main {
    background: transparent;
    padding: 0;
    margin: 0 auto;
    height: 594px;
    max-height: 594px;
  }
}

#Footer {

  background-color: transparent;
  background-image: linear-gradient(180deg, #DC842D 0%, #DC842D 100%);
  border-radius: 50px 50px 0px 0px;
}

.hiper-color{
  background-color: transparent;
  background-image: linear-gradient(180deg, #DC842D 0%, #DC842D 100%);
  border-radius: 50px 50px 0px 0px;
}

#Footer {
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  bottom: 135px;
}

.box-input{
  width: 300px;
}
.PhoneInputInput{

}
.content_capsula{
  min-width: 20rem;
  width:100%;
  display:flex;
  justify-content:center;
}

@media screen and (max-width: 627px) {
  .content_capsula{
    min-width: 20rem;
    width:100%;
    display:flex;
    justify-content:center;

  } 
}